// Libraries
import React, { createContext, useContext } from 'react';
import { universe } from '@bitsoex/react-design-system';

import useCatalogues, { UseCataloguesDataType, GetCurrencyMetadataType } from './hooks/useCatalogues';

// Types
import CataloguesType from './types/catalogues';

type CataloguesContextType = {
  catalogues: UseCataloguesDataType;
  getCurrencyMetadata: GetCurrencyMetadataType;
};

const CataloguesContext = createContext<CataloguesContextType>({
  catalogues: { data: null, loaded: false, loadedWithErrors: false },
  getCurrencyMetadata: (currency, defaultMetadata) => ({
    code: currency,
    full_name: currency,
    color: universe.moonlight40,
    precision: 2,
    iba_terms: [],
    type: '',
    ...defaultMetadata,
  }),
});

export const CataloguesContextProvider: React.VFC<{ children?: React.ReactElement; testObject?: CataloguesType }> = ({
  children,
  testObject,
}): JSX.Element => {
  const { catalogues, getCurrencyMetadata } = useCatalogues(testObject);

  return (
    <CataloguesContext.Provider value={{ catalogues, getCurrencyMetadata }}>{children}</CataloguesContext.Provider>
  );
};

export const useCataloguesContext = (): CataloguesContextType => useContext(CataloguesContext);
