import React, { createContext, useContext } from 'react';

import useLanguage, { ILanguageWithCountry, LanguageEnum } from './hooks/useLanguage';

type UseLanguageType = {
  language: { data: ILanguageWithCountry; loaded: boolean; loadedWithErrors: boolean };
  setLanguage: (newLanguage: ILanguageWithCountry) => void;
};

const LanguageContext = createContext<UseLanguageType>({
  language: { data: LanguageEnum['en-us'], loaded: false, loadedWithErrors: false },
  setLanguage: () => null,
});

export const LanguageContextProvider: React.VFC<{ children?: React.ReactElement }> = ({ children }): JSX.Element => {
  const { language, setLanguageState } = useLanguage();

  return (
    <LanguageContext.Provider value={{ language, setLanguage: setLanguageState }}>{children}</LanguageContext.Provider>
  );
};

export const useLanguageContext = (): UseLanguageType => useContext(LanguageContext);
