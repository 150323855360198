import './index.css';
import './styles/App.scss';
import '@src/legacy/Icon/icons/style.css';
import './styles/designSystem.scss';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { MSThemeProvider } from '@bitsoex/react-design-system';

import IntlProvider from '@src/providers/IntlProvider';
import FeatureFlagsProvider from '@src/context/FeatureFlagsContext';
import { CataloguesContextProvider } from '@src/context/CataloguesContext';
import { TransfersContextProvider } from '@src/context/TransfersContext';
import { LanguageContextProvider } from '@src/context/LanguageContext';
import BusinessRouter from './components/BusinessRouter';

const App = (): JSX.Element => (
  <Suspense fallback={null}>
    <MSThemeProvider modeOverwrittenByProduct legacyProduct="light">
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          errorRetryCount: 1,
        }}
      >
        <LanguageContextProvider>
          <CataloguesContextProvider>
            <TransfersContextProvider>
              <FeatureFlagsProvider>
                <IntlProvider>
                  <BrowserRouter>
                    <BusinessRouter />
                  </BrowserRouter>
                </IntlProvider>
              </FeatureFlagsProvider>
            </TransfersContextProvider>
          </CataloguesContextProvider>
        </LanguageContextProvider>
      </SWRConfig>
    </MSThemeProvider>
  </Suspense>
);

export default App;
