// Libraries
import { useEffect, useState, useCallback } from 'react';
import useSWRImmutable from 'swr/immutable';
import API from '@src/library/API';

// Types
import AccountStatusType from '../types/accountStatus';

export type UseAccountStatusDataType = {
  data: AccountStatusType | null;
  loaded: boolean;
  loadedWithErrors: boolean;
};

const INITIAL_STATE = { data: null, loaded: false, loadedWithErrors: false };
const SIGN_COMPANY_TERMS = '0706';

const useAccountStatus = (
  testObject?: AccountStatusType
): { accountStatus: UseAccountStatusDataType; clearData: () => void } => {
  const [accountStatus, setAccountStatus] = useState<UseAccountStatusDataType>(INITIAL_STATE);

  const { data, error, mutate } = useSWRImmutable<AccountStatusType>(
    testObject ? null : 'account_status',
    async url => {
      const {
        data: { payload: accountStatusPayload },
      } = await API.get(url);

      return accountStatusPayload;
    },
    {
      onError: err => {
        if (err?.response?.data?.error?.code === SIGN_COMPANY_TERMS) window.location.replace('/wallet#terms-updated');
      },
    }
  );

  const clearData = useCallback(() => {
    setAccountStatus(INITIAL_STATE);
    mutate();
  }, [mutate]);

  useEffect(() => {
    setAccountStatus({
      data: testObject || data || null,
      loaded: Boolean(testObject) || Boolean(data) || Boolean(error),
      loadedWithErrors: Boolean(error),
    });
  }, [testObject, data, error]);

  return { accountStatus, clearData };
};

export default useAccountStatus;
