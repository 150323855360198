// Libraries
import { useEffect, useState } from 'react';
import useSWRImmutable from 'swr/immutable';
import API from '@src/library/API';
import { universe } from '@bitsoex/react-design-system';

import { useAccountStatusContext } from '@src/context/AccountStatusContext';
// Types
import CataloguesType, { CurrencyMetadata } from '../types/catalogues';

export type UseCataloguesDataType = {
  data: CataloguesType | null;
  loaded: boolean;
  loadedWithErrors: boolean;
};

export type GetCurrencyMetadataType = (
  currency: string,
  defaultMetadata?: Partial<CurrencyMetadata>
) => CurrencyMetadata;

export type UseCataloguesResponseType = {
  catalogues: UseCataloguesDataType;
  getCurrencyMetadata: GetCurrencyMetadataType;
};

const INITIAL_STATE = { data: null, loaded: false, loadedWithErrors: false };

const useCatalogues = (testObject?: CataloguesType): UseCataloguesResponseType => {
  const [catalogues, setCatalogues] = useState<UseCataloguesDataType>(INITIAL_STATE);

  const {
    accountStatus: { loaded, data: accountStatusData },
  } = useAccountStatusContext();

  const { data, error } = useSWRImmutable<CataloguesType>(testObject ? null : 'catalogues', async url => {
    const {
      data: { payload: cataloguesPayload },
    } = await API.get(url);

    return { ...cataloguesPayload, restrictedBusinessCountries: [] };
  });

  useEffect(() => {
    if (loaded && accountStatusData && catalogues.data) {
      const restrictedCountries: string[] = [];
      Object.keys(catalogues.data.countries).forEach(countryKey => {
        const isUserFromCountry = countryKey === accountStatusData.country_of_residence;
        if (catalogues.data?.countries[countryKey]['business_restricted_country'] && !isUserFromCountry) {
          restrictedCountries.push(countryKey);
        }
      });
      const newData = { ...catalogues };
      if (newData.data?.restrictedBusinessCountries) {
        newData.data.restrictedBusinessCountries = restrictedCountries;
      }
      setCatalogues(newData);
    }
  }, [loaded, accountStatusData, catalogues.data]);

  useEffect(() => {
    setCatalogues({
      data: testObject || data || null,
      loaded: Boolean(testObject) || Boolean(data) || Boolean(error),
      loadedWithErrors: Boolean(error),
    });
  }, [testObject, data, error]);

  const getCurrencyMetadata: GetCurrencyMetadataType = (currency, defaultMetadata) => {
    const defaultData = {
      code: currency,
      full_name: currency,
      color: universe.moonlight40,
      precision: 2,
      iba_terms: [],
      type: '',
      ...defaultMetadata,
    };

    if (!catalogues.data) return defaultData;
    return catalogues.data.currencies.metadata.find(metadata => metadata.code === currency) || defaultData;
  };

  return {
    catalogues,
    getCurrencyMetadata,
  };
};

export default useCatalogues;
