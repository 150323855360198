import { useState, useEffect } from 'react';

export const LanguageEnum = {
  'en-us': 'en-us',
  'es-mx': 'es-mx',
  'es-ar': 'es-ar',
  'es-co': 'es-co',
  'pt-br': 'pt-br',
} as const;

export const LocaleLanguage = {
  us: 'en-us',
  mx: 'es-mx',
  ar: 'es-ar',
  co: 'es-co',
  br: 'pt-br',
} as const;

export const DEFAULT_LANGUAGE = LanguageEnum['en-us'];

export type ILanguageWithCountry = BitsoGlobals.Values<typeof LanguageEnum>;

export type UseLanguageDataType = {
  data: ILanguageWithCountry;
  loaded: boolean;
  loadedWithErrors: boolean;
};

export type UseLanguageType = {
  language: UseLanguageDataType;
  setLanguageState: (newLanguage: typeof LanguageEnum[keyof typeof LanguageEnum]) => void;
};

const useLanguage = (): UseLanguageType => {
  const [language, setLanguage] = useState<UseLanguageDataType>({
    data: 'en-us',
    loaded: true,
    loadedWithErrors: false,
  });

  useEffect(() => {
    if (language.data !== DEFAULT_LANGUAGE) {
      localStorage.setItem('settings:language', language.data);
    }

    setLanguage(oldLanguage => {
      const newLanguage: UseLanguageDataType = {
        ...oldLanguage,
        data: oldLanguage.data ? (oldLanguage.data.toLowerCase() as ILanguageWithCountry) : DEFAULT_LANGUAGE,
      };
      let localStorageLanguage = localStorage.getItem('settings:language');
      localStorageLanguage = localStorageLanguage ? localStorageLanguage.toLowerCase() : 'en-us';

      const newValue =
        (localStorageLanguage in LanguageEnum && LanguageEnum[localStorageLanguage as keyof typeof LanguageEnum]) ||
        LanguageEnum[DEFAULT_LANGUAGE];

      newLanguage.data = newValue;

      return newLanguage;
    });
  }, [language.data]);

  const setLanguageState = async (newLanguage: typeof LanguageEnum[keyof typeof LanguageEnum]) => {
    localStorage.setItem('settings:language', newLanguage);

    try {
      setLanguage({
        ...language,
        data: newLanguage,
        loaded: true,
      });
    } catch {
      setLanguage({
        ...language,
        loaded: true,
        loadedWithErrors: true,
      });
    }
  };

  return { language, setLanguageState };
};

export default useLanguage;
