// API/WS host and v3 paths
let apiHost = `${process.env.REACT_APP_API_HOST || window.location.origin}/`;
let apiV3Path = `${apiHost}api/v3/`;

// MSW API Host. Used only in development
export const MSW = {
  host: apiV3Path.slice(-1) === '/' ? apiV3Path.slice(0, -1) : apiV3Path,
};

// THIRD PARTY CONFIGS
// Datadog rum
const datadogApplicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID;
const datadogClientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
const datadogRumSite = 'datadoghq.com';
// setting datadog service
const datadogService = process.env.REACT_APP_DATADOG_SERVICE_NAME || 'react-web';
// Apptimize
const apptimizeKey = process.env.REACT_APP_APPTIMIZE_KEY || process.env.NEXT_PUBLIC_APPTIMIZE_KEY;

// same as the one choosen to create the build ie.  staging, malta
export const environment = process.env.ENVIRONMENT || '';

/**
 * This is a condition needed for preview environments.
 *
 * What:
 *  In the future we're exclusively going to have two configuration environments: preview and production
 *  In which the preview will be on demand development/testing environments and production is going to keep the same as today
 *
 *  Also in the future there won't be distinction between dev and stage, therefore all services like datadog, segment, clevertap will rely
 *  on one of these two configuration environments: production or testing (the last used only by the preview environments)
 *
 *  That being said you can assume that only two different config files (and local of course) are going to be needed: preview (testing env) and production.
 *
 * Why:
 *   That will help scalability since currently for each environment we need a separate config, a separate build and a separate docker image (see https://github.com/bitsoex/bitso-docker-getting-started)
 *   But with this approach we only need to build two images: production and preview environment
 *
 * How:
 *   The preview environments configuration is going to rely on the testing environment configuration for the services mentioned above and we're only going to customize some configuration
 *   via runtime asserting by the REACT_APP_PREVIEW_ENV_ENABLE environment variable
 */
const isPreviewEnvEnabled = process.env.REACT_APP_PREVIEW_ENV_ENABLE;
if (isPreviewEnvEnabled && isPreviewEnvEnabled !== 'false') {
  apiHost = `${window.location.origin}/`;
  apiV3Path = `${apiHost}api/v3/`;
}

// Recaptcha config
const recaptchaCode = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;

// On Storybook the recaptcha will be disabled by default
// Storybook exposes the STORYBOOK env variable automatically.
const recaptchaDisabled = process.env.REACT_APP_DISABLE_RECAPTCHA || process.env.STORYBOOK;

export const zendeskSrc = process.env.REACT_APP_ZENDESK;

// getting commit sha to use as release-version https://docs.datadoghq.com/real_user_monitoring/guide/upload-javascript-source-maps/?tabs=webpackjs#upload-your-source-maps
const commitSha = process.env.REACT_APP_VERSION || '';

const fpjsApiKey = process.env.REACT_APP_FPJS_API_KEY || '';
const fpjsVersion = process.env.REACT_APP_FPJS_VERSION;
const fpjsSubdomain = process.env.REACT_APP_FPJS_SUBDOMAIN;

const config = {
  apiV3Path,
  apptimizeKey,
  environment,
  recaptchaCode,
  recaptchaDisabled,
  fpjsApiKey,
  fpjsVersion,
  fpjsSubdomain,
  datadogApplicationId,
  datadogClientToken,
  datadogRumSite,
  datadogService,
  commitSha,
};

export default config;
