import { LooseAutocomplete } from '@src/types';

export const locales = [
  {
    country: 'us',
    countryName: 'United States',
    currency: 'usd',
    language: 'en',
    locale: 'en-us',
  },
  {
    country: 'mx',
    countryName: 'México',
    currency: 'mxn',
    language: 'es',
    locale: 'es-mx',
  },
  {
    country: 'ar',
    countryName: 'Argentina',
    currency: 'ars',
    language: 'es',
    locale: 'es-ar',
  },
  {
    country: 'co',
    countryName: 'Colombia',
    currency: 'cop',
    language: 'es',
    locale: 'es-co',
  },
  {
    country: 'br',
    countryName: 'Brasil',
    currency: 'brl',
    language: 'pt',
    locale: 'pt-br',
  },
] as const;

export const localeDefault = locales[0];

/**
 * From a country, a `locale` will be returned if
 * supported, otherwise the default will be returned.
 */
export const getLocaleFromCountry = (country: LooseAutocomplete<typeof locales[number]['country']>) =>
  locales.find(locale => locale.country === country)?.locale || localeDefault.locale;

/**
 * From a locale, a `country` will be returned if
 * supported, otherwise the default will be returned.
 */
export const getCountryFromLocale = (locale: LooseAutocomplete<typeof locales[number]['locale']>) =>
  locales.find(data => data.locale === locale)?.country || localeDefault.country;

/**
 * From a currency, a `locale` will be returned if
 * supported, otherwise the default will be returned.
 */
export const getLocaleFromCurrency = (currency: LooseAutocomplete<typeof locales[number]['currency']>) =>
  locales.find(data => data.currency === currency)?.locale || localeDefault.locale;

/**
 * From a locale, a `currency` will be returned if
 * supported, otherwise the default will be returned.
 */
export const getCurrencyFromLocale = (locale: LooseAutocomplete<typeof locales[number]['locale']>) =>
  locales.find(data => data.locale === locale)?.currency || localeDefault.currency;
