export default Object.freeze({
  BUSINESS_REVIEW_ENABLED: 'business_review_enabled',
  BUSINESS_REGISTER_ENABLED: 'business_register_enabled',
  BUSINESS_BASIC_INFORMATION_ENABLED: 'business_basic_information_enabled',
  BUSINESS_UPLOAD_DOCUMENTS_ENABLED: 'business_upload_documents_enabled',
  BUSINESS_SUPPORT_ENABLED: 'business_support_enabled',
  BUSINESS_TERMS_ENABLED: 'business_terms_enabled',
  BUSINESS_LOGIN_ENABLED: 'business_login_enabled_v2',
  ENABLE_NEWSLETTER_CHECKBOX: 'news_checkbox_auto_enabled',
  ENABLE_DFP_VERSION: 'enable_dfp_version_1',
} as const);
