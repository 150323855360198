import en_us from './en_us.json';
import es_mx from './es_mx.json';
import es_ar from './es_ar.json';
import es_co from './es_co.json';
import pt_br from './pt_br.json';

export const messages = {
  'en-us': en_us,
  'es-ar': es_ar,
  'es-co': es_co,
  'es-mx': es_mx,
  'pt-br': pt_br,
} as const;
