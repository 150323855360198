// Libraries
import { useEffect, useState } from 'react';
import useSWRImmutable from 'swr/immutable';
import API from '@src/library/API';
import cookies from 'js-cookie';

import { TransfersType } from '../types/transfers';

export type UseTransfersDataType = {
  data: TransfersType | null;
  loaded: boolean;
  loadedWithErrors: boolean;
};

const INITIAL_STATE = { data: null, loaded: false, loadedWithErrors: false };

const useTransfers = (testObject?: TransfersType): UseTransfersDataType => {
  const [transfers, setTransfers] = useState<UseTransfersDataType>(INITIAL_STATE);

  const { data, error } = useSWRImmutable<TransfersType>(testObject ? null : 'pending_bt', async url => {
    const btuid = cookies.get('btuid');

    if (!btuid) return;

    const {
      data: { payload: transfersPayload },
    } = await API.get(`${url}?fid=${btuid}`);

    return transfersPayload;
  });

  useEffect(() => {
    setTransfers({
      data: testObject || data || null,
      loaded: Boolean(testObject) || Boolean(data) || Boolean(error),
      loadedWithErrors: Boolean(error),
    });
  }, [testObject, data, error]);

  return transfers;
};

export default useTransfers;
