// Libraries
import React, { createContext, useContext } from 'react';
import useAccountStatus, { UseAccountStatusDataType } from './hooks/useAccountStatus';

// Types
import AccountStatusType from './types/accountStatus';

// Types
type AccountStatusContextType = { accountStatus: UseAccountStatusDataType };

const AccountStatusContext = createContext<AccountStatusContextType>({
  accountStatus: { data: null, loaded: false, loadedWithErrors: false },
});

export const AccountStatusContextProvider: React.VFC<{
  children?: React.ReactElement;
  testObject?: AccountStatusType;
}> = ({ children, testObject }) => {
  const { accountStatus } = useAccountStatus(testObject);

  return <AccountStatusContext.Provider value={{ accountStatus }}>{children}</AccountStatusContext.Provider>;
};

export const useAccountStatusContext = (): AccountStatusContextType => useContext(AccountStatusContext);
