const BREAKPOINTS = Object.freeze({
  screenUHD: '3840px',
  screenQHD: '2560px',
  screenWide: '1920px',
  screenLarge: '1440px',
  screenDesktop: '1280px',
  tabletLandscape: '1024px',
  tabletPortrait: '768px',
  phoneXl: '414px',
  phoneLg: '411px',
  phoneMd: '375px',
  phoneSm: '360px',
  phoneXs: '320px',
} as const);

export const BREAKPOINTS_VALUES = Object.freeze({
  screenWide: 1920,
  screenDesktop: 1280,
  tabletLandscape: 1024,
  tabletPortrait: 768,
  phoneXl: 414,
  phoneLg: 411,
  phoneMd: 375,
  phoneSm: 360,
  phoneXs: 320,
} as const);

export const viewports = Object.freeze({
  mobile: 'mobile',
  tablet: 'tablet',
  tabletLandscape: 'tablet-landscape',
  desktop: 'desktop',
} as const);

export const getViewport = (): BitsoGlobals.Values<typeof viewports> => {
  const size = window.innerWidth;
  if (size >= BREAKPOINTS_VALUES.screenDesktop) return viewports.desktop;
  if (size >= BREAKPOINTS_VALUES.tabletLandscape) return viewports.tabletLandscape;
  if (size >= BREAKPOINTS_VALUES.tabletPortrait) return viewports.tablet;
  return viewports.mobile;
};

export default BREAKPOINTS;
