import { IntlProvider as ProviderIntl } from 'react-intl';

import { LanguageContextProvider, useLanguageContext } from '@src/context/LanguageContext';
import { localeDefault } from '@src/library/locale';
import { translation } from '@src/translations';

type IntlProviderProps = {
  children: React.ReactNode;
};

const IntlProvider: BitsoGlobals.Component<IntlProviderProps> = props => {
  const { children } = props;
  const { language } = useLanguageContext();
  const messages = translation.messages[language.data];

  return (
    <ProviderIntl defaultLocale={localeDefault.locale} locale={language.data} messages={messages}>
      {children}
    </ProviderIntl>
  );
};

export default (props: IntlProviderProps) => (
  <LanguageContextProvider>
    <IntlProvider {...props} />
  </LanguageContextProvider>
);
