import { useCallback, useEffect, useState } from 'react';
import Apptimize from '@apptimize/apptimize-web-sdk';

import config from '@src/config';
import featureFlagsEnum from '@src/constants/feature-flags';

export type FeatureFlags = BitsoGlobals.Values<typeof featureFlagsEnum>;

export type Internals = {
  isFeatureFlagEnabled: (flag: FeatureFlags) => boolean;
  getExperimentVariant: (experimentId: string) => string | null;
  setCustomerUserId: (userId: number) => void;
  loaded: boolean;
};

export type UseFeatureFlagsType = Internals;

export type State = { loaded: boolean };

const useFeatureFlags = (): UseFeatureFlagsType => {
  const [state, setState] = useState<State>(() => ({ loaded: false }));

  const init = useCallback(async () => {
    const onApptimizeInitialized = () => setState({ loaded: true });
    Apptimize.setOnApptimizeInitializedCallback(onApptimizeInitialized);
    Apptimize.setup(config.apptimizeKey as string);
  }, []);

  useEffect(() => {
    if (!state.loaded) init();
  }, [init, state.loaded]);

  const setCustomerUserId = (clientId: number) => Apptimize.setCustomerUserId(String(clientId));

  const isFeatureFlagEnabled = useCallback(
    (flag: FeatureFlags): boolean => {
      if (!state.loaded) return false;
      return Apptimize.isFeatureFlagEnabled(flag);
    },
    [state]
  );

  const getExperimentVariant = useCallback(
    (experimentId: string): string | null => {
      if (!state.loaded) return null;
      return Apptimize.getString(experimentId, '');
    },
    [state.loaded]
  );

  return {
    isFeatureFlagEnabled,
    getExperimentVariant,
    setCustomerUserId,
    loaded: state.loaded,
  };
};

export default useFeatureFlags;
