// Libraries
import { createContext, useContext } from 'react';

import useTransfers, { UseTransfersDataType } from './hooks/useTransfers';

// Types
import { TransfersType } from './types/transfers';

const TransfersContext = createContext<UseTransfersDataType>({
  data: null,
  loaded: false,
  loadedWithErrors: false,
});

export const TransfersContextProvider: React.VFC<{ children?: React.ReactElement; testObject?: TransfersType }> = ({
  children,
  testObject,
}): JSX.Element => {
  const data = useTransfers(testObject);

  return <TransfersContext.Provider value={data}>{children}</TransfersContext.Provider>;
};

export const useTransfersContext = (): UseTransfersDataType => useContext(TransfersContext);
